import React from 'react'
import TableRow from './TableRow';


export default function Table({starters, mains, desserts}) {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="2">CHRISTMAS FOODSLUT MENU</th>
              
                </tr>
            </thead>

            <tbody>
                <tr colSpan="4"><td className="table-heading" colSpan="4">STARTERS</td></tr>
       
                {starters.map((item, i) => (<TableRow image={item}  key={i} name={item.title} description={item.description} price={item.price} />))}
                <tr colSpan="4"><td className="table-heading" colSpan="4">MAINS</td></tr>

                    {mains.map((item, i) => (<TableRow image={item}  key={i} name={item.title} description={item.description} price={item.price} />))}

                    <tr colSpan="4"><td className="table-heading" colSpan="4">DESSERT</td></tr>

                    {desserts.map((item, i) => (<TableRow image={item}  key={i} name={item.title} description={item.description} price={item.price} />))}

               

            </tbody>
        </table>
    )
}
