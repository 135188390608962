import React from "react"
import Hero from '../components/Hero';
import Layout from "../components/shared/layout"
import LayoutContent from "../components/shared/layoutContent"
// import Instagram from '../components/shared/Instagram';
import Button from '../components/BurgerHero/Button';
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Table from '../components/shared/Table';
import FollowFooter from '../components/shared/FollowFooter';
import Map from '../components/Map/Map'
import Gallary from '../components/shared/Gallary';
import Video from "../components/Video";
const RestaurantPage = ({ data }) => {
  const { logo, starters, mains, desserts, dishes } = data.allContentfulRestaurant.edges[0].node
  // const content = [childContentfulAboutPageMainCopyRichTextNode]
  return (
    <Layout>
      <SEO title="FoodSlut at Provisioners" />
      <Hero
        title="FoodSlut at Provisioners"
        techStack="Christmas FoodSlut"
        image={logo}
        logo={true}
      />
      <LayoutContent>
      <div className="competition__image-container">
                    <div className="video-container">
                        <Video src="https://www.youtube.com/embed/Y4WIXqmXDo4" youTube={true} />
                    </div>

                </div>

        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="tile is-vertical instructions">
              <Table
                starters={starters}
                mains={mains}
                desserts={desserts}
              />
            </div>
          </div>
          <div className="tile is-4 is-vertical is-parent">
            <div className="tile is-child box">
              <div className="recipe__image-container">
                <p className="title">Reservations</p>
                <p className="title is-6">reservations@provisioners.co.uk </p>
                <a href="https://bda.bookatable.com/?cid=UK-RES-PROVISIONERS_455973:97899&amp;rid=455973">
                  <Button copy="Book a Table" />
                </a>
                <br></br>   <br></br>
                <p className="title is-6">Christmas FoodSlut at Provisioners</p> 
                <p className="title is-6">Set Menu £35pp</p> 

         

              </div>

            </div>
            <div className="tile is-child box">
              <div className="recipe__image-container">
                <div className="">
                  <Map  lat={51.502680} lng={-0.076170} />
                  <div className="address-container">
                    <p className="title is-5">Provisioners</p>
                    <p className="title is-6">4 Queen Elizabeth St</p>
                    <p className="title is-6">London </p>
                    <p className="title is-6">SE1 2LL</p>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="columns is-multiline  ">
          {dishes.map((item, idx) => (<Gallary data={item} key={idx} />))}

        </div>
        <FollowFooter
          link="https://bda.bookatable.com/?cid=UK-RES-PROVISIONERS_455973:97899&amp;rid=455973"
          copy="Make a Reservation"
          buttonCopy="Book a Table"

        />
      </LayoutContent>
    </Layout>
  )
}

export default RestaurantPage

export const query = graphql`
query{
    allContentfulRestaurant {
        edges {
          node {
            id
            title
            slug
            logo {
              fluid(maxWidth: 500, maxHeight:500){
                src
              ...GatsbyContentfulFluid
            }
          }
          starters {
            id
            description
            title
            fluid (maxWidth: 300, maxHeight:300) {
                ...GatsbyContentfulFluid
        
            }
          }
          mains {
            id
            description
            title
            fluid (maxWidth: 300, maxHeight:300) {
                ...GatsbyContentfulFluid
        
            }
          }
          desserts {
            id
            description
            title
            fluid (maxWidth: 300, maxHeight:300) {
                ...GatsbyContentfulFluid
        
            }
          }
    
          dishes {
            id
            fluid (maxWidth: 500, maxHeight:500) {
                ...GatsbyContentfulFluid
        
            }
          }
          }
        }
  }
}`


